<template>
  <v-layout row justify-center>
      <v-card style="width: 100%">
          <v-form @submit="UpdateEditedAccount($v.editAccount)">
              <v-app-bar dense absolute text class="fixedToolbar" style="z-index: 2;">
                  <v-toolbar-title v-bind:class="{'headline grey-text': !drawer, 'padLeft headline grey-text': drawer }">Edit Account</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon type="submit" v-on:click.prevent="UpdateEditedAccount($v.editAccount)">
                      <v-icon color="green">fas fa-check</v-icon>
                  </v-btn>
                  <v-btn icon @click.native="$router.push({ path: '/dashboard/account' })">
                      <v-icon color="red">fas fa-times</v-icon>
                  </v-btn>
              </v-app-bar>

              <v-card-text style="padding-top: 0;">
                  <v-container grid-list-md class="padContentLess">
                      <v-layout wrap>
                          <v-flex xs12>
                              <v-text-field label="Account Name" v-model="editAccount.accountName"
                                            :error-messages="accountNameErrors"
                                            @input="$v.editAccount.accountName.$touch()"
                                            @blur="$v.editAccount.accountName.$touch()"></v-text-field>
                          </v-flex>
                          <v-flex xs12>
                              <v-autocomplete :items="types"
                                              v-model="editAccount.accountType"
                                              label="Account Type"
                                              :error-messages="accountTypeErrors"
                                              @input="$v.editAccount.accountType.$touch()"
                                              @blur="$v.editAccount.accountType.$touch()"></v-autocomplete>
                          </v-flex>
                          <v-flex xs12>
                              <v-text-field label="Account Description" v-model="editAccount.accountDescription"></v-text-field>
                          </v-flex>
                          <!--<v-flex xs12>
                      <v-text-field label="Account Amount" type="number" min="0" v-model="editAccount.accountAmount"></v-text-field>
                    </v-flex>-->
                          <v-flex xs12>
                              <v-autocomplete label="Account Currency"
                                              :items="curriences"
                                              v-model="editAccount.accountCurrency"
                                              :error-messages="accountCurrencyErrors"
                                              @input="$v.editAccount.accountCurrency.$touch()"
                                              @blur="$v.editAccount.accountCurrency.$touch()"
                                              item-text="currencyName"
                                              item-value="currencyShort">
                                  <template slot="selection"
                                            slot-scope="data">
                                      <v-chip :input-value="data.selected"
                                              class="chip--select-multi">
                                          {{ data.item.currencyName }}
                                      </v-chip>
                                  </template>
                                  <template slot="item"
                                            slot-scope="data">

                                      <template>
                                          <v-list-item-content>
                                              <v-list-item-title v-html="data.item.currencyName"></v-list-item-title>
                                              <v-list-item-subtitle v-html="data.item.currencyShort"></v-list-item-subtitle>
                                          </v-list-item-content>
                                      </template>
                                  </template>
                              </v-autocomplete>
                          </v-flex>

                      </v-layout>
                  </v-container>
              </v-card-text>
          </v-form>
      </v-card>
  </v-layout>

</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'
  
  export default {
    mixins: [validationMixin],
    props: ['drawer'],
    data() {
      return {
        sameAccount: false,
        types: ["Bank", "Saving", "Checking", "Cash", "Other"],
        curriences: [],
        editAccount: {
          id: '',
          accountName: '',
          accountType: '',
          accountDescription: '',
          accountCurrency: '',
          accountAmount: 0,
          objectState: ''
        },
        items: []
      }
    },
    computed: {
      accountNameErrors() {
        const errors = []
        if (!this.$v.editAccount.accountName.$dirty) return errors
        this.sameAccount && errors.push('An Account already has this Name. Enter a new Name of update Account')
        !this.$v.editAccount.accountName.required && errors.push('Account Name is required')
        return errors
      },
      accountTypeErrors() {
        const errors = []
        if (!this.$v.editAccount.accountType.$dirty) return errors
        !this.$v.editAccount.accountType.required && errors.push('Account Type is required')
        return errors
      },
      //accountDescriptionErrors() {
      //  const errors = []
      //  if (!this.$v.editAccount.accountDescription.$dirty) return errors
      //  !this.$v.editAccount.accountDescription.required && errors.push('Account Description is required')
      //  return errors
      //},
      accountCurrencyErrors() {
        const errors = []
        if (!this.$v.editAccount.accountCurrency.$dirty) return errors
        !this.$v.editAccount.accountCurrency.required && errors.push('Account Currency is required')
        return errors
      }
    },
    methods: {
      UpdateEditedAccount: function ($v) {
        $v.$touch(); //Triggers Error Messages

        if ($v.accountName.$invalid || $v.accountCurrency.$invalid) {
          return
        }

        this.editAccount.objectState = "Update"

        this.$store.commit('updateAccountinStore', this.editAccount)

        this.$router.push({ path: '/dashboard/account' })
      },
      EditAccount: function (item) {

        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].id === parseInt(item)) {
            let editEntry = JSON.parse(JSON.stringify(this.items[i]))
            this.editAccount = editEntry
            break
          }
        }
        
      },
      removeDeletedAccountsFromItems: function () {
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].objectState === 'Delete') {
            this.items.splice(i, 1)
          }
        }
      },
      getAvailableCurriencies: function () {
        let cur = this.$store.state.Currencies

        for (let i = 0; i < cur.length; i++) {
          if (cur[i].ObjectState !== "Delete") {
            this.curriences.push(cur[i])
          }
        }
      }
    },
    beforeMount() {
      this.items = this.$store.state.Accounts

      ///Remove deleted items from items array
      this.removeDeletedAccountsFromItems()

      this.getAvailableCurriencies()

      this.EditAccount(this.$route.params.itemId)
    },
    validations: {
      editAccount: {
        accountName: {
          required
        },
        accountType: {
          required
        },
        //accountDescription: {
        //  required
        //},
        accountCurrency: {
          required
        }
      }
    }
    
  }
</script>
